.row-image-container {
    display: flex;
    flex-direction: row;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.row-image-container .left {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.left .item {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    min-height: 60px;
}

.item img {
    width: 35px;
}