.loader-absolute {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.1);
}

.login-absolute {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.background-none {
    background: transparent;
}

.product-list-img {
    width: 100px;
    max-height: 100px;
}

/*=======LOADER CSS=====*/
#ajax_data {
    width: 100%
}

.loading-wrapper {
    position: fixed;
    float: left;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 12;
    text-align: center;
    background: #f2f2f7;
}

.page-overlay {
    float: left;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.loading-wrapper.content-loader {
    position: absolute;
    height: 100%;
}

.loading-wrapper.content-relative {
    position: relative;
    height: 200px;
    background: none;
    z-index: 1;
}

.loading-wrapper.section-loader {
    position: absolute;
    z-index: 13;
    background: none;
}

.loading-wrapper.section-loader .page-overlay {
    height: 56vh;
}

.loading-wrapper.main_loader {
    background: #f2f2f7;
    z-index: 100;
}

.loading-wrapper.scroll-loader {
    position: relative;
    height: 80px;
}

.loading-wrapper.content-relative .page-overlay {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader-ring {
    display: inline-block;
    position: relative;
    width: 42px;
    height: 42px;
    border: 5px solid #009e51;
    border-color: #009e51 transparent #009e51 #009e51;
    animation: loader-ring 650ms linear infinite;
    border-radius: 50%;
}

.loader-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 42px;
    height: 42px;
    margin: 6px;
    border: 5px solid #009e51;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    animation: loader-ring 1.4s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #009e51 transparent transparent transparent;
}

.loader-ring div:nth-child(1) {
    animation-delay: -0.44s;
}

.loader-ring div:nth-child(2) {
    animation-delay: -0.28s;
}

.loader-ring div:nth-child(3) {
    animation-delay: -0.14s;
}

.loading-wrapper.mini-loader {
    position: relative;
    z-index: 8;
}

.loading-wrapper.mini-loader .page-overlay {
    height: 100%;
}

.loading-wrapper.mini-loader .loader-ring {
    height: 34px;
    width: 34px;
}

.btn-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.btn-loader .loader-ring {
    height: 20px;
    width: 20px;
    border-width: 3px;
    border-color: #fff;
    border-top-color: transparent;
}

.welcome-content .loading-wrapper.mini-loader {
    background: #fff;
    margin-bottom: 10px;
    margin-top: 10px;
}

.reveal-count-outer .loading-wrapper.mini-loader {
    background: #fff;
}

@keyframes loader-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/*collapsible components*/
.collapsible {
    background-color: #f8f9fa;
    color: white;
    cursor: pointer;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
}

.hidden {
    display: none;
    opacity: 0;
}

.collapsible.active, .collapsible:hover {
    background-color: #f4f5f6;
}

.collapsible:after {
    content: '\002B';
    color: #6c757d;
    font-weight: bold;
    float: right;
    margin-left: 5px;
}

.collapsible.active:after {
    content: "\2212";
}

.content {
    padding: 0 18px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    background-color: #f1f1f1;
}

.content p {
    margin-bottom: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.cursor-pointer {
    cursor: pointer;
}